import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Gym, GymExecutionMode } from '../../interfaces/gym';
import { AlertsService } from '../../services/alerts.service';
import { GymsService } from '../../services/gyms.service';
import { GlobalService } from '../../services/global.service';
import { GymType } from '../../interfaces/gymtype';
import { Chain } from '../../interfaces/chain';
import { ChainsService } from '../../services/chains.service';
import { Status } from '../../interfaces/status';
import { TranslateService } from '@ngx-translate/core';
import { TrainingMode } from '../../interfaces/trainingmode';
import { Application } from '../../interfaces/application';
import { ApplicationVersion } from '../../interfaces/applicationversion';
import { GymStationType } from '../../interfaces/gymstationtype';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

import { GymProgram } from '../../interfaces/gymprogram';
import { Mood } from '../../interfaces/mood';
import { GymTimetable } from '../../interfaces/gymtimetable';
import { FloorType } from '../../interfaces/floortype';
import { SubscriptionsGymItemExt, SubscriptionsOrder } from '../../interfaces/subscription';
import { GymFormat } from '../../interfaces/gymformat';

@Component({
  selector: 'app-gym-detail',
  templateUrl: './gym-detail.component.html',
  styleUrls: ['./gym-detail.component.scss']
})
export class GymDetailComponent implements OnInit {
  canCreate: boolean;
  canModify: boolean;

  gym: Gym = {
    id: 0,
    idPramaCloud: null,
    status: 0,
    name: '',
    creationDate: '',
    type: null,
    format: null,
    chain: null,
    orderId: '',
    address: '',
    address2: '',
    city: '',
    zip: '',
    country: '',
    hrType: 0,
    phone: '',
    license: '',
    checkLicense: true,
    logo: '',
    trainingModes: [],
    contacts: [],
    forcedDependencies: [],
    applications: [],
    stations: [],
    kiosks: [],
    programs: [],
    options: {
      standByMood: constants.moodIcons.defaultMood,
      shutDownMood: constants.moodIcons.offMood
    },
    floorType: 0,
    standalone: false,
    improvedRanking: false,
    executionModes: [],
    oem: false,
    showLetterStation: false
  };

  isNew: Boolean = false;

  logoPath: string;

  gymTypeList: GymType[];
  gymFormatList: GymFormat[];
  chainList: Chain[];
  gymStatusList: Status[];
  trainingModeList: TrainingMode[];
  executionModeList: GymExecutionMode[];
  applicationList: Application[];
  applicationVersionList: ApplicationVersion[];
  stationTypesList: GymStationType[];
  programList: GymProgram[];
  specificProgramList: GymProgram[];
  creatorPrograms: GymProgram[];
  moodList: Mood[];
  timetable: GymTimetable[];

  public gymProgramList: SubscriptionsGymItemExt[];
  public gymPackList: SubscriptionsGymItemExt[];
  public gymProList: SubscriptionsGymItemExt[];
  public gymBootiqList: SubscriptionsGymItemExt[];
  public gymOrderList: SubscriptionsOrder[];

  constructor(private route: ActivatedRoute,
    private gymsService: GymsService,
    private alertsService: AlertsService,
    private chainsService: ChainsService,
    public globalService: GlobalService,
    private translate: TranslateService,
    private authService: AuthService) {

    this.globalService.getGymTypes().subscribe(result => { this.gymTypeList = result; });
    this.globalService.getGymFormats().subscribe(result => { this.gymFormatList = result });
    this.chainsService.getChains().subscribe(result => { this.chainList = result; });
    this.globalService.getGymStatus().subscribe(result => { this.gymStatusList = result; });
    this.trainingModeList = this.route.snapshot.data.global.trainingModeList;
    this.moodList = this.route.snapshot.data.global.moodList;
    this.applicationList = this.route.snapshot.data.global.applicationList;
    this.stationTypesList = this.route.snapshot.data.global.stationTypesList;
    this.specificProgramList = this.route.snapshot.data.programList;
    this.creatorPrograms = this.route.snapshot.data.creatorPrograms;
    this.timetable = this.route.snapshot.data.timetable;
    this.executionModeList = this.globalService.getExecutionModes();
  }

  ngOnInit() {
    if (this.route.snapshot.data.gym === 'new') {
      this.isNew = true;

      this.translate.get('GYMS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });

      // Creamos ExecutionModes ausentes en new Gym
      this.executionModeList.forEach(executionMode => {
        this.gym.executionModes.push(
          {
            id: executionMode.id,
            name: executionMode.name,
            active: false
          }
        );
      });

    } else {
      this.gym = this.route.snapshot.data.gym;
      this.gymProgramList = this.route.snapshot.data.gymProgramList;
      this.gymPackList = this.route.snapshot.data.gymPackList;
      this.gymProList = this.route.snapshot.data.gymProList;
      this.gymBootiqList = this.route.snapshot.data.gymBootiqList;
      this.gymOrderList = this.route.snapshot.data.gymOrderList;
      this.programList = this.gym.programs;
      this.globalService.changePageTitle([this.gym.name, '(#' + this.gym.id + ')']);

    }

    this.canCreate = this.authService.isInRole(constants.roles.gym_create);
    this.canModify = this.authService.isInRole(constants.roles.gym_update);

    this.logoPath = constants.urlAPP + constants.logos.urlGyms;
  }

  public saveGym() {
    this.gymsService.postGymTimetable(this.timetable, this.gym.id).subscribe(
      result => {
      },
      error => {
        console.log('Error', error);
      }
    );
    this.gymsService.putGym(this.gym).subscribe(
      result => {
        this.translate.get('GYMS.GYM_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createGym() {
    this.gymsService.postGym(this.gym).subscribe(
      result => {
        this.translate.get('GYMS.GYM_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  onSubmit() {
    if (this.canCreate || this.canModify) {
      if (this.isNew) {
        this.createGym();
      } else {
        this.saveGym();
      }
    }
  }

  getMoodIconUrl(mood: number) {
    if (mood !== null && mood !== undefined) {
      return constants.moodIcons.path + mood.toString() + '.png';
    }
    return constants.moodIcons.path + constants.moodIcons.offMood + '.png';
  }

  async postFile(data: File): Promise<any> {
    this.globalService.postFile(data).subscribe(
      fileName => {
        this.logoPath = constants.urlAPP + constants.urlTempFiles;
        this.gym.logo = fileName;
      }, error => {
        this.translate.get('GYMS.ERROR_UPLOAD_LOGO').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }

  // Para ver si tiene Bootiq por ejemplo, para activar casilla OEM
  public hasProgramEnabled(programTypeId: number, status: number){
    if(this.programList !== undefined && this.programList !== null){
      return this.programList.findIndex(program => program.type === programTypeId && program.status === status) !== -1;
    }
    else {
      return false;
    }
  }

  public hasProSuscription() {
    if(!this.isNew) {
      return this.gymProList && this.gymProList.length === 1 && 
              this.gymProList[0].status === 1;
    }
  }

}
