import { BodyChallengesService } from './../../../services/body-challenges.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './../../../services/global.service';
import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { BodyChallenge, ChallengeSendMeasurement, ChallengeUser } from './../../../interfaces/bodychallenge';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { constants } from '../../../../assets/constants';

@Component({
  selector: 'app-body-challenge-form',
  templateUrl: './body-challenge-form.component.html',
  styleUrls: ['./body-challenge-form.component.scss']
})
export class BodyChallengeFormComponent implements OnInit {

  bodyChallengeForm: BodyChallenge;

  canCreate: boolean;
  canFill: boolean;

  //displayedColumns = ['name', 'initDate', 'finishDate', 'status', 'actions'];
  displayedColumns = ['name'];
  dataSource = new MatTableDataSource;

  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private bodyChallengesService: BodyChallengesService,
    private authService: AuthService) {

    this.bodyChallengeForm = this.route.snapshot.data.bodyChallengeForm;
    console.log(this.bodyChallengeForm);

    this.translate.get('BODY_CHALLENGES.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.management_body_challenge_editor);
    this.canFill = this.authService.isInRole(constants.roles.management_body_challenge_editor);
  }

  ngOnInit() {
    this.populateData(0);
  }

  ngAfterViewInit() {
    this.updateData();
  }

  updateData() {
    this.dataSource = new MatTableDataSource(this.bodyChallengeForm.challenges[0].users); //Initial tab es 0
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  public populateData(challengeIndex: number) {

    this.displayedColumns = ['name', 'totalPoints'];
    this.bodyChallengeForm.challenges[challengeIndex].users[0].measurements.forEach(measurement => {
      this.displayedColumns.push(measurement.name);
    });
    this.dataSource = new MatTableDataSource(this.bodyChallengeForm.challenges[challengeIndex].users);
  }

  public createNewMeasurement() {
    this.bodyChallengesService.postMeasurement(this.bodyChallengeForm.id).subscribe(
      result => {

        this.bodyChallengesService.getBodyChallengeForm(this.bodyChallengeForm.id).subscribe(
          result => {
            this.bodyChallengeForm = result;
            console.log(this.bodyChallengeForm);
            this.populateData(0);
            this.updateData();
          },
          error => {
            console.log('Error', error);
          }
        );
      },
      error => {
        console.log('Error', error);
      }
    );

  }

  public fieldChanged(idUser, idMeasurement, idParameter, value) {
    let newMeasure: ChallengeSendMeasurement = {
      idUser: idUser,
      idMeasurement: idMeasurement,
      idParameter: idParameter,
      value: value
    };

    this.bodyChallengesService.putMeasurement(newMeasure).subscribe(
      result => {

      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public getTotalPoints(user: ChallengeUser){
    let totalPoints: number = 0;
    user.measurements.forEach(measurement => {
      totalPoints += Number(measurement.measurementPoints);
    });
    return totalPoints ? Math.round(totalPoints*100)/100 : 0;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


}
