import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AlertsService } from '../../../services/alerts.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { GymAlert } from '../../../interfaces/gymalert';
import { GymAlertsService } from '../../../services/gymalerts.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-gym-alerts',
  templateUrl: './gym-alerts.component.html',
  styleUrls: ['./gym-alerts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GymAlertsComponent implements OnInit {

  //@Input() appId: number;
  @Input() itemList: GymAlert[];

  displayedColumns = ['name', 'alert', 'testAlert', 'pm', 'bos', 'actions'];
  dataSource = new MatTableDataSource;
  expandedElement: GymAlert | null;
  contactList: any;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public editMode: number;
  isDateError: Boolean = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private gymAlertsService: GymAlertsService, 
    private alertsService: AlertsService,
    private route: ActivatedRoute) {
    this.editMode = 0;
  }

  ngOnInit() {
    this.contactList = this.route.snapshot.data.contactList;
  }

  ngAfterViewInit() {
    this.refreshTableData();
  }

  private refreshTableData() {
    let currentId = 1;
    // this.itemList.forEach(x => {
    //   x.id = currentId;
    //   currentId++;
    // });
    this.dataSource = new MatTableDataSource(this.itemList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  private editItem(item: any) {
    this.editMode = item.gymId;
  }

  private saveItem(item: any) {
    this.gymAlertsService.putGymAlerts(item).subscribe(
      result => {
        this.alertsService.alertTrigger({ msg: 'Information update' })
      },
      error => {
        console.log('Error', error);
        this.alertsService.alertTrigger({ msg: 'Error saving data' })
      }
    );
    this.resetEditMode();
  }

  // private checkDateChange(item: any) {
  //   const dateFrom = new Date(item.activeFrom);
  //   const dateUntil = new Date(item.activeUntil);
  //   if (dateFrom && dateUntil) {
  //     if (dateFrom > dateUntil) {
  //       this.isDateError = true;
  //       return false;
  //     }
  //   }
  //   this.isDateError = false;
  //   return true;
  // }

  private resetEditMode() {
    this.editMode = 0;
    this.isDateError = false;
    this.expandedElement = null;
  }

  // public getStatus(statusId: number){
  //   switch(statusId){
  //     case 0: return 'PROGRAMS.STATUS_DISABLED'; break;
  //     case 1: return 'PROGRAMS.STATUS_ENABLED'; break;
  //     case 2: return 'PROGRAMS.STATUS_DEMO'; break;
  //   }
  // }

  public getValue(value: any) {
    if (!value) {
      return '-';
    } else {
      return value;
    }
  }

  public isDate(date) {
    return Date.parse(date);
  }

  add(event: MatChipInputEvent, gymAlert: GymAlert, field: string): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      gymAlert[field].push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: string, gymAlert: GymAlert, field: string): void {
    const index = gymAlert[field].indexOf(item);

    if (index >= 0) {
      gymAlert[field].splice(index, 1);
    }
  }

  getContact(contactId: number){
    let contact = this.contactList.find(contact => contact.id == contactId);
    return contact;
  }
  

}
