import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { VersionsService } from '../../services/versions.service';
import { ApplicationVersion } from '../../interfaces/applicationversion';
import { AlertsService } from '../../services/alerts.service';
import { Application } from '../../interfaces/application';
import { GlobalService } from '../../services/global.service';
import { MatDatepicker } from '@angular/material';
import { SessionToken } from '../../interfaces/sessiontoken';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../../../assets/constants';
import { AuthService } from '../../services/auth.service';
import { Gym } from '../../interfaces/gym';

@Component({
  selector: 'app-version-detail',
  templateUrl: './version-detail.component.html',
  styleUrls: ['./version-detail.component.scss']
})
export class VersionDetailComponent implements OnInit {
  @ViewChild(MatDatepicker, {static: false}) datepicker: MatDatepicker<Date>;

  canCreate: boolean;
  canModify: boolean;

  version_obj: ApplicationVersion = {
    id: null,
    application: null,
    version: '',
    creationDate: '',
    description: '',
    applyFrom: '',
    active: true,
    filename: '',
    gymsActive: [],
    dependencies: [],
    kioskStatus: []
  };

  isNew: Boolean = false;
  appType: number;
  modalRef: BsModalRef;

  applicationList: Application[];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private alertsService: AlertsService,
    private modalService: BsModalService,
    private globalService: GlobalService,
    private versionsService: VersionsService,
    private translate: TranslateService,
    private authService: AuthService) {

    this.appType = 0;
  }

  ngOnInit() {
    if (this.route.snapshot.data.version_obj === 'new') {
      this.isNew = true;

      this.translate.get('VERSIONS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.version_obj = this.route.snapshot.data.version_obj;

      this.globalService.changePageTitle(
        [
          this.globalService.getApplications().filter(app => (app.id === this.version_obj.application))[0].name,
          'v' + this.version_obj.version
        ]);
    }

    this.canCreate = this.authService.isInRole(constants.roles.application_version_create);
    this.canModify = this.authService.isInRole(constants.roles.application_version_modify);

    this.applicationList = this.route.snapshot.data.applicationList;
    this.versionChanged();
  }

  public saveVersion() {
    this.versionsService.putVersion(this.version_obj).subscribe(
      result => {
        this.translate.get('VERSIONS.VERSION_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createVersion() {

    this.version_obj.id = 0;
    const today = new Date();
    if (!this.version_obj.applyFrom) {
      this.version_obj.applyFrom = today.toISOString();
    }
    this.version_obj.creationDate = today.toISOString();

    this.versionsService.postVersion(this.version_obj).subscribe(
      result => {
        this.translate.get('VERSIONS.VERSION_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public deleteVersion() {
    this.versionsService.deleteVersion(this.version_obj.id).subscribe(
      result => {
        this.router.navigate(['/versions']);
        this.translate.get('VERSIONS.VERSION_DELETED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  versionChanged() {
    if (this.applicationList) {
      const application = this.applicationList.filter(app => (app.id === this.version_obj.application));
      if (application[0] !== undefined) {
        this.appType = application[0].type;
      }
    }
  }

  private openDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' });
  }

  private acceptDelete() {
    this.deleteVersion();
    this.modalRef.hide();
  }

  private cancelDelete() {
    this.modalRef.hide();
  }

  onSubmit() {
    if (this.isNew) {
      this.createVersion();
    } else {
      this.saveVersion();
    }
  }


  async postFile(data: File): Promise<any> {
    this.globalService.postFile(data).subscribe(
      fileName => {
        this.version_obj.filename = fileName;
      }, error => {
        this.translate.get('VERSIONS.ERROR_UPLOAD_VERSION').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }
}
