import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { GymsService } from '../../../services/gyms.service';
import { Gym } from '../../../interfaces/gym';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-chain-gym-list',
  templateUrl: './chain-gym-list.component.html',
  styleUrls: ['./chain-gym-list.component.scss']
})
export class ChainGymListComponent implements OnInit, AfterViewInit {

  @Input() chainId;
  @Input() gymList: Gym[];

  gymsInChain: Gym[];

  private displayedColumns = ['name', 'city', 'country'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor() {
    this.gymsInChain = [];
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getGymsByChain(this.chainId);
  }

  private getGymsByChain(chainId: number) {
    this.gymsInChain = this.gymList.filter(gym => (gym.chain === chainId));
    if (this.gymsInChain.length > 0) {
      this.dataSource = new MatTableDataSource(this.gymsInChain);
      this.dataSource.paginator = this.paginator;
      // this.dataSource.paginator.firstPage();
      this.dataSource.sort = this.sort;
    }
  }

}
